"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';

const TplFormula = forwardRef(
  (_, ref) => {
    console.error("TplFormula: Unsupported formula type used");
    return /* @__PURE__ */ jsx(ComboBoxTag, { ref, disabled: true, children: "Unsupported formula type" });
  }
);
TplFormula.displayName = "TplFormula";

export { TplFormula as default };

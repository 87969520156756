"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import clsx from 'clsx';
import { css } from '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import '../../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../../styled-system/jsx/box.mjs';
import '../../../styled-system/patterns/visually-hidden.mjs';
import classes from './Editor.module.css.mjs';
import { EditorTagNode } from './nodes/EditorTagNode.mjs';
import { ConfigPlugin } from './plugins/ConfigPlugin.mjs';
import { EditorTagPlugin } from './plugins/EditorTagPlugin.mjs';
import { KeyboardPlugin } from './plugins/KeyboardPlugin.mjs';
import { StateSyncPlugin } from './plugins/StateSyncPlugin.mjs';

function onError(error) {
  console.error(error);
}
const initialConfig = {
  namespace: "data-input-editor",
  onError,
  nodes: [EditorTagNode]
};
function LexicalProvider({ children }) {
  return /* @__PURE__ */ jsx(LexicalComposer, { initialConfig, children });
}
const PLACEHOLDER_CSS_VARIABLE = "--placeholder-text";
const placeholderEmulation = css({
  "& p": {
    _before: {
      content: `var(${PLACEHOLDER_CSS_VARIABLE})`,
      float: "left",
      pointerEvents: "none",
      h: 0,
      color: "gray.9"
    }
  }
});
const LexicalEditor = forwardRef(({ onChange, value, placeholder, disabled, ...props }, ref) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { className: clsx(classes.editor), children: /* @__PURE__ */ jsx(
      PlainTextPlugin,
      {
        contentEditable: /* @__PURE__ */ jsx(
          ContentEditable,
          {
            ref,
            spellCheck: false,
            ...props,
            "data-testid": "data-input-editor",
            "data-editor-container": "true",
            "data-placeholder": placeholder,
            className: clsx(props.className, placeholderEmulation),
            style: {
              [PLACEHOLDER_CSS_VARIABLE]: `"${placeholder}"`
            }
          }
        ),
        ErrorBoundary: LexicalErrorBoundary
      }
    ) }),
    /* @__PURE__ */ jsx(HistoryPlugin, {}),
    /* @__PURE__ */ jsx(EditorTagPlugin, {}),
    /* @__PURE__ */ jsx(KeyboardPlugin, {}),
    /* @__PURE__ */ jsx(
      OnChangePlugin,
      {
        ignoreSelectionChange: true,
        ignoreHistoryMergeTagChange: true,
        onChange
      }
    ),
    /* @__PURE__ */ jsx(StateSyncPlugin, { value }),
    /* @__PURE__ */ jsx(ConfigPlugin, { disabled })
  ] });
});
LexicalEditor.displayName = "LexicalEditor";

export { LexicalEditor, LexicalProvider };

// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '71d911948aa5a5a84fa1aafdb65c3927';
const css =`@layer elements{._hbox_6m3sw_1,._vbox_6m3sw_1{--pane-padding-x:initial;--pane-padding-x-100:2.25rem;--pane-padding-x-90:1.25rem}._hbox_6m3sw_1{flex-direction:row}._hbox_6m3sw_1,._vbox_6m3sw_1{align-items:flex-start;display:flex;height:100%;justify-content:flex-start;max-height:100%;max-width:100%;min-width:0;width:100%}._vbox_6m3sw_1{flex-direction:column}._pane_6m3sw_1{flex-grow:0;flex-shrink:0;padding-left:var(--pane-padding-x);padding-right:var(--pane-padding-x)}._pane_6m3sw_1._pane__stretch_6m3sw_1{flex-grow:1;flex-shrink:1}._pane_6m3sw_1._pane__scrollable_6m3sw_1,._pane_6m3sw_1._pane__stretch_6m3sw_1{overflow:auto}._hbox_6m3sw_1>._pane_6m3sw_1{height:100%;max-width:100%}._vbox_6m3sw_1>._pane_6m3sw_1{max-height:100%;width:100%}}`;
const styles = {
    get ['hbox']() { injectStyles(key, css, options);  return '_hbox_6m3sw_1'; },
    get ['vbox']() { injectStyles(key, css, options);  return '_vbox_6m3sw_1'; },
    get ['pane']() { injectStyles(key, css, options);  return '_pane_6m3sw_1'; },
    get ['pane__stretch']() { injectStyles(key, css, options);  return '_pane__stretch_6m3sw_1'; },
    get ['paneStretch']() { injectStyles(key, css, options);  return '_pane__stretch_6m3sw_1'; },
    get ['pane__scrollable']() { injectStyles(key, css, options);  return '_pane__scrollable_6m3sw_1'; },
    get ['paneScrollable']() { injectStyles(key, css, options);  return '_pane__scrollable_6m3sw_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;

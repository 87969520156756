"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { transformValueToTpl, getTemplateFromTplValue, getValuesFromTplValue, splitStringToChunks, isTagChunk, getTagChunkValue } from './common.mjs';

function transformValueToEditorSchema(value) {
  const safeValue = transformValueToTpl(value);
  const template = getTemplateFromTplValue(safeValue);
  const values = getValuesFromTplValue(safeValue);
  const chunks = splitStringToChunks(template);
  const nodes = chunks.map(
    (chunk) => {
      if (isTagChunk(chunk)) {
        const tagValue = getTagChunkValue(chunk);
        const value2 = values[tagValue];
        return {
          type: "editor-tag-node",
          value: { value: value2, autoOpen: false, id: tagValue },
          version: 1
        };
      }
      return {
        type: "text",
        version: 1,
        detail: 0,
        format: 0,
        mode: "normal",
        style: "",
        text: chunk
      };
    }
  );
  const paragraph = {
    children: nodes,
    direction: "ltr",
    format: "",
    indent: 0,
    textFormat: 0,
    textStyle: "",
    type: "paragraph",
    version: 1
  };
  const res = {
    root: {
      children: [paragraph],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1
    }
  };
  return res;
}

export { transformValueToEditorSchema };

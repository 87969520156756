"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.1.7
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { LexicalProvider } from '../ComboBoxElements/Editor/editor.mjs';
import { ComboBoxProvider } from './context/combobox-context.mjs';
import { ComboBoxDropdownContextProvider } from './context/combobox-dropdown-context.mjs';
import { ComboBoxDropdownSearchProvider } from './context/combobox-dropdown-search.mjs';
import { canUserEnterValueFromKeyboard } from './context/helpers.mjs';
import { OptionSelector } from './Edit/OptionSelector.mjs';
import { TextInput } from './Edit/TextInput.mjs';
import { ValueType } from './value-spec.mjs';

function ComboBox({
  value,
  schema,
  valueSpec = { type: ValueType.ANY, allowCustom: true },
  variablesSchema,
  variablesOnly = false,
  options = void 0,
  optionFactories = [],
  onChange,
  valueComponent = void 0,
  placeholder = "",
  isRequired = false,
  disabled = false,
  className,
  hideSearch = false,
  // Dropdown related
  autoOpen = false,
  onOpen,
  onClose,
  topDropdownPanelComponent = void 0,
  refsToIgnoreClick
}) {
  const userCanEnterValueFromKeyboard = canUserEnterValueFromKeyboard(
    schema,
    options,
    optionFactories,
    variablesOnly,
    value
  );
  const dropdownProps = {
    autoOpen,
    onDropdownOpen: onOpen,
    onDropdownClose: onClose,
    topDropdownPanelComponent
  };
  const props = {
    placeholder,
    className,
    onChange,
    required: isRequired,
    disabled
  };
  return /* @__PURE__ */ jsx(
    ComboBoxProvider,
    {
      value,
      schema,
      valueSpec,
      variablesSchema,
      options,
      optionFactories,
      refsToIgnoreClick,
      ...dropdownProps,
      children: /* @__PURE__ */ jsx(ComboBoxDropdownSearchProvider, { hideSearch, children: /* @__PURE__ */ jsx(ComboBoxDropdownContextProvider, { children: /* @__PURE__ */ jsx(LexicalProvider, { children: userCanEnterValueFromKeyboard ? /* @__PURE__ */ jsx(TextInput, { ...props }) : /* @__PURE__ */ jsx(
        OptionSelector,
        {
          ...props,
          valueComponent
        }
      ) }) }) })
    }
  );
}

export { ComboBox };
